<template>
  <b-overlay
    :show="loading"
    v-if="company"
  >
    <b-row>
      <b-col class="text-left">
        <h2>{{ company.text }}</h2>
      </b-col>
      <b-col class="text-right small">
        <QueueStatus
          :status="status"
          @refresh="$root.$emit('bv::refresh::table', 'processingTable')"
        ></QueueStatus>
      </b-col>
    </b-row>

    <b-table
      id="processingTable"
      :fields="headers"
      :items="getRecords"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.reverse"
      bordered
      sticky-header="600px"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      striped
    >
      <template #cell(linkedqbaccount)="data">
        {{ data.item.linkedqbaccount }}
        <b-icon-info-circle-fill
          class="pointer text-primary mr-2"
          @click.prevent="selectedTransaction = data.item; showTransactionDetail = true"
          title="Transaction Details"
        >
        </b-icon-info-circle-fill>
      </template>
      <template #cell(qbtype)="data">
        {{ data.item.qbtype.replace(/([A-Z])/g, ' $1') }}
      </template>

      <template #cell(amount)="data">
        <strong>{{ data.item.amount | currency }}</strong>
      </template>

      <template #cell(qbentity)="data">
        {{ (data.item.qbentity) ? data.item.qbentity : data.item.vendorname }}
      </template>

      <template #cell(started)="data">
        <span
          v-if="data.item.errorcode"
          class="text-danger"
        >
          {{ data.errorcode }}: {{ data.error }}
        </span>
        <span v-else-if="data.item.completed">Success</span>
        <span v-else-if="data.item.started">Processing</span>
        <span v-else>Queued</span>
      </template>
    </b-table>

    <b-row class="h-100">
      <b-col class="text-left">{{ records.length }} transactions processing</b-col>
      <b-col class="text-right">
        Total: <strong>{{ total | currency }}</strong>
      </b-col>
    </b-row>

    <b-modal
      v-model="showTransactionDetail"
      size="lg"
      v-if="selectedTransaction"
      no-close-on-backdrop
      no-close-on-esc
      @close="selectedTransaction = null"
      @cancel="selectedTransaction = null"
    >
      <TransactionDetail :transaction="selectedTransaction"></TransactionDetail>
    </b-modal>
  </b-overlay>
</template>
<script>
import PlaidService from '@/services/PlaidService'
import TransactionDetail from '@/components/bookkeeping/widgets/TransactionDetail'
import QueueStatus from '@/components/bookkeeping/widgets/QueueStatus'

export default {
  name: 'Processing',
  components: { TransactionDetail, QueueStatus },
  props: {
    company: {
      type: Object,
      default () { return null }
    },
    period: {
      type: String,
      default () { return null }
    },
    refresh: Boolean
  },

  data () {
    return {
      loading: false,
      showTransactionDetail: false,
      showTransactionRecode: false,
      showProcessQueueConfirmation: false,
      selectedTransaction: null,
      records: [],
      total: 0,
      status: null,
      filter: null,
      headers: [
        {
          key: 'businessdate',
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'linkedqbaccount',
          label: 'Source',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbtype',
          label: 'Type',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbentity',
          label: 'Vendor',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbaccount',
          label: 'Account',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbclass',
          label: 'Class',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          tdClass: 'align-middle text-right',
          thClass: 'text-right'
        },
        {
          key: 'started',
          label: 'Status',
          sortable: true,
          tdClass: 'align-middle'
        }
      ],
      sort: {
        column: 'businessdate',
        reverse: false
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (!this.company || !this.company.value) {
        return false
      }
      if (this.loading) {
        return false
      }
      this.$root.$emit('bv::refresh::table', 'processingTable')
    },

    getRecords (ctx) {
      if (!this.company || !+this.company.value) {
        return null
      }
      const data = {
        company_id: +this.company.value,
        period: this.period,
        processing: true,
        perPage: 0,
        page: 1,
        sort: {
          column: ctx.sortBy,
          reverse: ctx.sortDesc
        }
      }
      return PlaidService.getTransactions(data).then((response) => {
        const curlen = (this.records) ? this.records.length : 0
        this.records = response.data.info.transactions
        this.total = response.data.info.amount
        this.status = response.data.info.status
        this.$emit('total', +this.records.length)
        if (curlen !== this.records.length) {
          this.$emit('refresh')
        }
        return this.records || []
      })
    }
  },

  watch: {
    refresh: function (n, o) {
      if (n && n !== o) {
        this.$root.$emit('bv::refresh::table', 'processingTable')
      }
    }
  }
}
</script>
