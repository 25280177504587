<template>
  <b-form-select
    v-model="selection"
    :options="options"
    @change="$emit('selected', selection)"
    size="sm"
    title="Quickbooks class"
    :class="(selection) ? ((coded) ? 'is-valid' : 'is-warning') : 'is-invalid'"
    class="small-select"
  >
    <template #first>
      <b-form-select-option :value="null">Select Class</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
export default {
  name: 'SelectClass',

  props: {
    options: Array,
    coded: Number,
    value: {
      type: [Number, String],
      default: null
    }
  },

  data () {
    return {
      selection: null
    }
  },

  mounted () {
    if (this.value) {
      this.selection = this.value
    }
    this.$emit('selected', this.selection)
  },

  watch: {
    value: function (n, o) {
      if (n !== o) {
        this.selection = n
        this.$emit('selected', this.selection)
      }
    }
  }
}
</script>
