<template>
  <b-overlay
    :show="loading"
    v-if="company"
  >
    <b-row>
      <b-col class="text-left">
        <h2>{{ company.text }}</h2>
      </b-col>
      <b-col class="text-right small">
        <QueueStatus
          :status="status"
          @refresh="$root.$emit('bv::refresh::table', 'queueTable')"
        ></QueueStatus>
      </b-col>
    </b-row>

    <b-table
      id="queueTable"
      :fields="headers"
      :items="getRecords"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.reverse"
      bordered
      sticky-header="600px"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      striped
    >
      <template #cell(qbtype)="data">
        {{ data.item.qbtype.replace(/([A-Z])/g, ' $1') }}
      </template>

      <template #cell(qbentity)="data">
        {{ (data.item.qbentity) ? data.item.qbentity : data.item.vendorname }}
      </template>

      <template #cell(amount)="data">
        <strong>{{ data.item.amount | currency }}</strong>
      </template>

      <template #cell(delete)="data">
        <b-icon-info-circle-fill
          class="pointer text-primary mr-2"
          @click.prevent="selectedTransaction = data.item; showTransactionDetail = true"
          title="Transaction Details"
        >
        </b-icon-info-circle-fill>

        <b-icon-trash-fill
          class="pointer text-danger"
          @click.prevent="selectedTransaction = data.item; showTransactionRecode = true"
          title="Move Back to Coding"
        ></b-icon-trash-fill>
      </template>
    </b-table>

    <b-row class="h-100">
      <b-col class="text-left">{{ records.length }} transactions queued</b-col>
      <b-col>
        <b-button
          variant="danger"
          @click.prevent="showProcessQueueConfirmation = true"
          :disabled="loading"
          size="sm"
        >Process Queue</b-button>
      </b-col>
      <b-col class="text-right">
        Total: <strong>{{ total | currency }}</strong>
      </b-col>
    </b-row>

    <b-modal
      v-model="showTransactionDetail"
      size="lg"
      v-if="selectedTransaction"
      no-close-on-backdrop
      no-close-on-esc
      @close="selectedTransaction = null"
      @cancel="selectedTransaction = null"
    >
      <TransactionDetail :transaction="selectedTransaction"></TransactionDetail>
    </b-modal>

    <b-modal
      v-model="showProcessQueueConfirmation"
      size="md"
      title="Process Transactions"
      button-size="sm"
      no-close-on-backdrop
      no-close-on-esc
      ok-variant="danger"
      ok-title="Process"
      @ok="process"
    >
      <p class="text-center">Are you sure you want to process all {{ this.records.length }} transactions in the queue?</p>
    </b-modal>

    <b-modal
      v-model="showTransactionRecode"
      size="md"
      title="Re-Code Transaction"
      button-size="sm"
      ok-variant="danger"
      ok-title="Re-Code"
      v-if="selectedTransaction"
      no-close-on-backdrop
      no-close-on-esc
      @close="selectedTransaction = null"
      @cancel="selectedTransaction = null"
      @ok="recodeTransaction(selectedTransaction)"
    >
      <p class="text-center">Are you sure you want to move this transaction back to coding?</p>
      <b-table-simple
        small
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th>Date</b-th>
            <b-th class="text-right">Amount</b-th>
            <b-th>Details</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ selectedTransaction.businessdate }}</b-td>
            <b-td class="text-right">{{ selectedTransaction.amount | currency }}</b-td>
            <b-td>{{ selectedTransaction.name }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </b-overlay>
</template>
<script>
import PlaidService from '@/services/PlaidService'
import TransactionDetail from '@/components/bookkeeping/widgets/TransactionDetail'
import QueueStatus from '@/components/bookkeeping/widgets/QueueStatus'

export default {
  name: 'TransactionQueue',
  components: { TransactionDetail, QueueStatus },
  props: {
    company: {
      type: Object,
      default () { return null }
    },
    period: {
      type: String,
      default () { return null }
    },
    refresh: Boolean
  },

  data () {
    return {
      loading: false,
      showTransactionDetail: false,
      showTransactionRecode: false,
      showProcessQueueConfirmation: false,
      selectedTransaction: null,
      records: [],
      total: 0,
      status: null,
      filter: null,
      headers: [
        {
          key: 'businessdate',
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'linkedqbaccount',
          label: 'Source',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbtype',
          label: 'Type',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbentity',
          label: 'Vendor',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbaccount',
          label: 'Account',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbclass',
          label: 'Class',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'delete',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          tdClass: 'align-middle text-right',
          thClass: 'text-right'
        }
      ],
      sort: {
        column: 'businessdate',
        reverse: false
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (!this.company || !this.company.value) {
        return false
      }
      if (this.loading) {
        return false
      }
      this.$root.$emit('bv::refresh::table', 'queueTable')
    },

    getRecords (ctx) {
      if (!this.company || !+this.company.value) {
        return null
      }
      const data = {
        company_id: +this.company.value,
        period: this.period,
        fullycoded: true,
        perPage: 0,
        page: 1,
        sort: {
          column: ctx.sortBy,
          reverse: ctx.sortDesc
        }
      }
      return PlaidService.getTransactions(data).then((response) => {
        const curlen = (this.records) ? this.records.length : 0
        this.records = response.data.info.transactions
        this.total = response.data.info.amount
        this.status = response.data.info.status
        this.$emit('total', +this.records.length)
        if (curlen !== this.records.length) {
          this.$emit('refresh')
        }
        return this.records || []
      })
    },

    recodeTransaction (t) {
      if (t && !this.loading) {
        this.loading = true
        PlaidService.recodeTransaction(+t.id).then(() => {
          this.$aimNotify.notify(null, 'info', 'Transaction Coding Reset', 'The transaction was successfully moved back to coding.')
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        }).finally(() => {
          this.selectedTransaction = null
          this.loading = false
          this.$root.$emit('bv::refresh::table', 'queueTable')
          this.$emit('refresh')
        })
      }
    },

    process () {
      if (this.loading) {
        return false
      }
      this.loading = true
      PlaidService.processQueue(+this.company.value).then(() => {
        this.$aimNotify.notify(null, 'info', 'Transaction Queue Processed', 'All transactions have been processed for Quickbooks.')
        this.$emit('switchtab', 'processing')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        this.$root.$emit('bv::refresh::table', 'queueTable')
        this.$emit('refresh')
      })
    }
  },

  watch: {
    refresh: function (n, o) {
      if (n && n !== o) {
        this.$root.$emit('bv::refresh::table', 'queueTable')
      }
    }
  }
}
</script>
