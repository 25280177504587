<style scoped>
.small-select {
  max-width: 350px;
  font-size: 90%;
}
</style>
<template>
  <b-overlay :show="loading">
    <b-form inline>
      <b-form-row class="mb-3">
        <b-col>
          <b-form-select
            v-if="companies.length > 0"
            v-model="selectedCompanyId"
            :options="companies"
            @change="loadCompany"
          >
            <template #first>
              <b-form-select-option :value="null">Select Company</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            v-if="company"
            v-model="selectedPeriod"
            :options="this.company.periods"
            class="mx-3"
            @change="$emit('selectPeriod', selectedPeriod); refreshTable()"
          >
            <template #first>
              <b-form-select-option :value="null">Select Date Period</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            v-if="company"
            v-model="selectedAccountId"
            :options="plaidaccounts"
            @change="refreshTable"
          >
            <template #first>
              <b-form-select-option :value="null">Filter by Account</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-form-row>
    </b-form>

    <b-table
      v-if="companyLoaded"
      id="codingTable"
      :fields="headers"
      :items="getRecords"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.reverse"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      no-sort-reset
    >
      <template #table-colgroup>
        <col>
        <col>
        <col>
        <col>
        <col width="250px">
        <col>
      </template>
      <template #head(delete)>
        Delete
        <b-form-checkbox id="select-all" @change="deleteAll($event)">
          <b-icon-trash-fill
          class="pointer"
          @click.prevent="deleteTransactionDialog()"
        ></b-icon-trash-fill>
        </b-form-checkbox>
      </template>
      <template #cell(amount)="data">
        <strong>{{ data.item.amount | currency }}</strong>
      </template>

      <template #cell(name)="data">
        <p
          class="m-0 pointer"
          @click.prevent="viewTransaction(data.item)"
        ><strong>{{ data.item.linkedqbaccount }}</strong></p>
        <p
          class="m-0 pointer"
          @click.prevent="viewTransaction(data.item)"
        >{{ data.item.category }}</p>
        <p
          class="m-0 pointer"
          @click.prevent="viewTransaction(data.item)"
        >{{ data.item.name }}</p>
      </template>

      <template #cell(qbaccount_id)="data">
        <b-row v-if="!data.item.pending || (+data.item.autosettle && transactionOldEnoughToForce(data.item.businessdate))">
          <b-col>
            <SelectTransactionType
              :value="data.item.qbtype"
              :options="transactiontypes"
              :coded="data.item.fullycoded"
              @selected="(v) => { setValue(data.item, 'qbtype', v) }"
              class="mb-1"
            ></SelectTransactionType>
            <br>
            <SelectAccount
              v-if="!loading"
              :options="(data.item.amount > 0) ? accounts.expense : accounts.income"
              @selected="(v) => { setValue(data.item, 'qbaccount_id', v) }"
              :value="data.item.qbaccount_id"
              :coded="data.item.fullycoded"
              class="mb-1"
            ></SelectAccount>
            <br>
            <SelectClass
              :options="classes"
              @selected="(v) => { setValue(data.item, 'qbclass_id', v) }"
              :value="data.item.qbclass_id"
              :coded="data.item.fullycoded"
            ></SelectClass>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <p class="m-0">Awaiting Settlement</p>
            <b-btn
              variant="danger"
              size="sm"
              @click.prevent="settleTransaction(data.item.id)"
              v-if="transactionOldEnoughToForce(data.item.businessdate)"
            >Force Settlement</b-btn>
          </b-col>
        </b-row>
      </template>

      <template #cell(qbentity_id)="data">
        <b-row
          v-if="!data.item.pending || (+data.item.autosettle && transactionOldEnoughToForce(data.item.businessdate))"
          style="max-width: 250px;"
          class="m-auto"
        >
          <b-col cols="12">
            <SelectVendor
              :options="entities"
              :value="data.item.qbentity_id"
              :coded="data.item.fullycoded"
              :vendorname="data.item.vendorname"
              @selected="(v) => { setValue(data.item, 'qbentity_id', v) }"
              @newvendor="(v) => { setValue(data.item, 'vendorname', v) }"
            ></SelectVendor>
          </b-col>

          <b-col>
            <b-form-group
              label="Ref #:"
              label-cols="3"
              label-align="right"
              class="m-0"
            >
              <b-form-input
                size="sm"
                v-model="data.item.qbrefnumber"
                class="small-select"
                maxlength="11"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Memo:"
              label-cols="3"
              label-align="right"
              class="m-0"
            >
              <b-form-input
                size="sm"
                class="small-select"
                v-model="data.item.qbmemo"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #cell(delete)="data">
          <b-form-checkbox v-model="data.item.delete">
            <b-icon-trash-fill
              class="pointer"
              @click.prevent="deleteTransactionDialog(data.item)"
            ></b-icon-trash-fill>
          </b-form-checkbox>
      </template>

      <template
        #custom-foot="data"
        v-if="totalRecords > 0"
      >
        <b-tr class="align-middle">
          <b-td :colspan="+data.columns">
            <b-row class="h-100">
              <b-col
                cols="12"
                sm="4"
                class="text-left"
              >
                {{ startNumber }} - {{ endNumber }} of {{ totalRecords }}<br>
                <div>
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols="auto"
                    label-size="sm"
                    class="mb-0"
                    v-if="+totalRecords > 10"
                  >
                    <b-form-select
                      id="per-page-select"
                      class="w-auto"
                      v-model="perPage"
                      size="sm"
                    >
                      <b-select-option :value="10">10</b-select-option>
                      <b-select-option :value="20">20</b-select-option>
                      <b-select-option :value="50" v-if="+totalRecords > 20">50</b-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-button
                  variant="success"
                  @click.prevent="save"
                  :disabled="loading"
                  size="sm"
                >Save Coding Changes</b-button>
              </b-col>
              <b-col
                cols="12"
                sm="4"
                v-if="+totalRecords > +perPage"
              >
                <b-row class="align-middle">
                  <b-col cols="12">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecords"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                      aria-controls="codingTable"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </template>
    </b-table>

    <b-modal
      v-model="showTransactionDetail"
      v-if="selectedTransaction"
      size="lg"
      :title="'Transaction ID ' + selectedTransaction.id"
      no-close-on-backdrop
      no-close-on-esc
    >
      <TransactionDetail :transaction="selectedTransaction"></TransactionDetail>
    </b-modal>

    <b-modal
      v-model="showTransactionDelete"
      size="md"
      title="Delete Transaction"
      button-size="sm"
      ok-variant="danger"
      ok-title="Delete"
      no-close-on-backdrop
      no-close-on-esc
      @close="cancelDelete()"
      @cancel="cancelDelete()"
      @ok="deleteTransaction(selectedTransaction)"
      auto-focus-button="ok"
    >
      <p class="text-center">Are you sure you want to delete {{ recordsForDeleting().length > 1 ? 'these transactions' : 'this transaction' }}?</p>
      <b-table-simple
        small
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th>Date</b-th>
            <b-th class="text-right">Amount</b-th>
            <b-th>Details</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template>
            <b-tr v-for="item in recordsForDeleting()" :key="item.id">
              <b-td>{{ item.businessdate }}</b-td>
              <b-td class="text-right">{{ item.amount | currency }}</b-td>
              <b-td>{{ item.name }}</b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </b-overlay>
</template>
<style scoped>
</style>
<script>
import PlaidService from '@/services/PlaidService'
import SelectTransactionType from '@/components/bookkeeping/widgets/SelectTransactionType'
import SelectVendor from '@/components/bookkeeping/widgets/SelectVendor'
import SelectAccount from '@/components/bookkeeping/widgets/SelectAccount'
import SelectClass from '@/components/bookkeeping/widgets/SelectClass'
import TransactionDetail from '@/components/bookkeeping/widgets/TransactionDetail'

export default {
  name: 'Coding',
  components: { SelectVendor, SelectAccount, SelectClass, SelectTransactionType, TransactionDetail },

  props: {
    companies: Array,
    companyId: {
      type: [Number, String],
      default: 0
    },
    date: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      companyLoaded: false,
      selectedCompanyId: null,
      selectedAccountId: null,
      selectedPeriod: null,
      company: null,
      transactiontypes: [
        { text: 'Deposit', value: 'Deposit' },
        { text: 'Check', value: 'Check' },
        { text: 'Credit Card Charge', value: 'CreditCardCharge' },
        { text: 'Credit Card Credit', value: 'CreditCardCredit' }
      ],
      entities: [],
      askAccounts: [],
      accounts: {
        expense: [],
        income: [],
        banks: [],
        others: []
      },
      classes: [],
      plaidaccounts: [],
      totalRecords: 0,
      records: [],
      selectedTransaction: null,
      showTransactionDetail: false,
      showTransactionDelete: false,
      checked: false,
      headers: [
        {
          key: 'businessdate',
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'amount',
          label: '$',
          sortable: true,
          tdClass: 'align-middle text-right',
          thClass: 'text-right'
        },
        {
          key: 'name',
          label: 'Plaid Vendor',
          sortable: true,
          tdClass: 'align-middle text-left',
          thClass: 'text-left'
        },
        {
          key: 'qbaccount_id',
          label: 'QB Transaction Info',
          tdClass: 'align-middle'
        },
        {
          key: 'qbentity_id',
          label: 'QB Vendor',
          tdClass: 'align-middle'
        },
        {
          key: 'delete',
          label: 'Delete',
          tdClass: 'align-middle text-left',
          sortable: false
        }
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      sort: {
        column: 'name',
        reverse: false
      }
    }
  },

  mounted () {
    if (!this.selectedCompanyId) {
      if (+this.companyId) {
        this.selectedCompanyId = +this.companyId
      } else if (this.companies && this.companies.length === 1) {
        this.selectedCompanyId = +this.companies[0].value
      }
    }

    if (!this.selectedPeriod) {
      if (this.date) {
        this.selectedPeriod = this.date
        this.$emit('selectPeriod', this.selectedPeriod)
      }
    }

    if (this.selectedCompanyId) {
      this.loadCompany()
    }
  },

  methods: {
    loadCompany () {
      if (this.loading) {
        return false
      }

      this.$emit('selectCompany', this.selectedCompanyId)

      this.entities = []
      this.accounts = {
        expense: [],
        income: [],
        banks: [],
        others: []
      }
      this.classes = []
      this.plaidaccounts = []

      if (!this.selectedCompanyId) {
        return false
      }

      this.loading = true
      this.companyLoaded = false
      PlaidService.getCompany(+this.selectedCompanyId).then(
        (response) => {
          this.company = response.data.info
          this.company.periods = this.company.periods.sort((a, b) => ('' + a).localeCompare(b))
          if (this.selectedPeriod) {
            // a period is set, make sure it exists as an option
            const periodFound = this.company.periods.some((p) => {
              if (p === this.selectedPeriod) {
                return true
              } else return false
            })
            if (!periodFound) {
              this.selectedPeriod = null
              this.$emit('selectPeriod', this.selectedPeriod)
            }
          }

          this.company.entities.forEach((e) => {
            this.entities.push({ text: e.name, value: +e.id, type: e.type })
          })
          this.company.accounts.expense.forEach((a) => {
            if (a.fullname.substring(0, 4) === 'Ask ') {
              this.askAccounts.push(+a.id)
            }
            this.accounts.expense.push({ text: a.fullname, value: +a.id, type: a.accounttype })
          })
          this.company.accounts.income.forEach((a) => {
            if (a.fullname.substring(0, 4) === 'Ask ') {
              this.askAccounts.push(+a.id)
            }
            this.accounts.income.push({ text: a.fullname, value: +a.id, type: a.accounttype })
          })
          this.company.accounts.banks.forEach((a) => {
            this.accounts.banks.push({ text: a.fullname, value: +a.id, type: a.accounttype })
          })
          this.company.accounts.others.forEach((a) => {
            if (a.fullname.substring(0, 4) === 'Ask ') {
              this.askAccounts.push(+a.id)
            }
            this.accounts.others.push({ text: a.fullname, value: +a.id, type: a.accounttype })
          })

          // add expenses to income and income to expenses because this is what they want
          const optsExp = {
            label: 'Expenses',
            options: JSON.parse(JSON.stringify(this.accounts.expense))
          }
          const optsInc = {
            label: 'Income',
            options: JSON.parse(JSON.stringify(this.accounts.income))
          }
          this.accounts.income.push(optsExp)
          this.accounts.expense.push(optsInc)

          if (this.accounts.banks.length > 0) {
            // append banks and crap to the end of expense and income account option lists
            const opts = {
              label: 'Bank/CC Accounts',
              options: this.accounts.banks
            }
            this.accounts.expense.push(opts)
            this.accounts.income.push(opts)
          }

          if (this.accounts.others.length > 0) {
            // append others and crap to the end of expense and income account option lists
            const opts = {
              label: 'Assets/Equity/Liabilities',
              options: this.accounts.others
            }
            this.accounts.expense.push(opts)
            this.accounts.income.push(opts)
          }
          this.company.classes.forEach((c) => {
            this.classes.push({ text: c.fullname, value: +c.id })
          })
          this.company.plaid.forEach((p) => {
            if (p.accounts && p.accounts.length) {
              p.accounts.forEach((a) => {
                if (+a.qbaccount_id > 0) {
                  this.plaidaccounts.push({ text: a.name, value: +a.id })
                } else if (!+a.ignore) {
                  // account not mapped and not ignored, switch to the settings tab
                  this.$emit('selectTab', 'settings')
                }
              })
            }
          })
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        this.companyLoaded = true
        this.refreshTable()
      })
    },

    refreshTable () {
      if (this.companyLoaded) {
        this.$root.$emit('bv::refresh::table', 'codingTable')
      }
    },

    setValue (item, key, val) {
      item[key] = val
      this.$nextTick(() => {
        if (item.qbtype !== null && item.qbaccount_id !== null && item.qbclass_id !== null) {
          item.fullycoded = 1
          this.codeSimilarTransactions(item)
        } else {
          item.fullycoded = 0
        }
      }, 100)
    },

    codeSimilarTransactions (item) {
      if (this.records && this.records.length > 1) {
        this.records.forEach((r) => {
          if (!r.pending && r.name === item.name && +r.id !== +item.id) {
            if (!r.qbentity_id && item.qbentity_id) {
              r.qbentity_id = item.qbentity_id
            } else if (!r.vendorname) {
              r.vendorname = item.vendorname
              if (!item.qbentity_id) {
                r.qbentity_id = null
              }
            }
            if (!r.qbclass_id) {
              r.qbclass_id = item.qbclass_id
            }
            if (!r.qbaccount_id || this.isAskAccount(r.qbaccount_id)) {
              r.qbaccount_id = item.qbaccount_id
            }
          }
        })
      }
    },

    isAskAccount (id) {
      return (this.askAccounts.indexOf(+id) !== -1)
    },

    getRecords (ctx) {
      if (!this.selectedCompanyId) {
        return null
      }
      this.loading = true
      const data = {
        company_id: +this.selectedCompanyId,
        period: this.selectedPeriod,
        fullycoded: false,
        perPage: +this.perPage,
        page: +this.currentPage,
        sort: {
          column: ctx.sortBy,
          reverse: ctx.sortDesc
        }
      }
      if (+this.selectedAccountId > 0) {
        data.plaidaccount_id = +this.selectedAccountId
      }
      return PlaidService.getTransactions(data).then((response) => {
        const curlen = (this.records) ? this.records.length : 0
        this.records = response.data.info.transactions
        this.totalRecords = +response.data.info.total
        this.setRefNumbers()
        this.$emit('total', +this.totalRecords)
        if (curlen !== this.records.length) {
          this.$emit('refresh')
        }
        return this.records || []
      }).finally(() => {
        this.loading = false
      })
    },
    deleteAll (n) {
      const boxes = document.querySelector('#codingTable')
      const checkboxes = boxes.querySelectorAll('input[type="checkbox"]')
      checkboxes.forEach(c => {
        if (!this.checked) {
          c.checked = true
        } else {
          c.checked = false
        }
      })
      if (n) {
        this.records.forEach(r => {
          r.delete = true
        })
      } else {
        this.records.forEach(r => {
          r.delete = false
        })
      }
      if (!this.checked) {
        this.checked = true
      } else {
        this.checked = false
      }
    },

    setRefNumbers () {
      const vendors = ['Paypal', 'Apple Pay', 'Bill Pay', 'Cash', 'Quick Pay', 'Square', 'Transfer', 'Venmo', 'Withdrawal', 'Zelle']

      if (!this.records || !this.records.length) {
        return false
      }
      this.records.forEach((r) => {
        const nameStr = String(r.name)

        if (!r.qbrefnumber || !r.qbrefnumber.length) {
          // set a reference number if available
          if (r.qbtype === 'Check') {
            const ptdata = JSON.parse(r.data)
            if (ptdata && ptdata.check_number && ptdata.check_number.length > 0) {
              r.qbrefnumber = ptdata.check_number
              // ach account is 21002000
            } else if (ptdata && ptdata.category_id && +ptdata.category_id === 21002000) {
              r.qbrefnumber = 'ACH'
            } else {
              r.qbrefnumber = 'Debit'
            }
          }

          vendors.some((v) => {
            const rgxp = new RegExp('.*' + v + '.*', 'g')
            if (nameStr.match(rgxp)) {
              r.qbrefnumber = v
              return true
            } else return false
          })
        }
      })
    },

    viewTransaction (t) {
      this.selectedTransaction = t
      if (Object.prototype.toString.call(this.selectedTransaction.data) === '[object String]') {
        this.selectedTransaction.data = JSON.parse(t.data)
      }
      if (this.selectedTransaction) {
        this.selectedTransaction.account = {
          name: t.plaidaccount,
          type: t.plaidaccounttype
        }
      }
      this.showTransactionDetail = true
    },

    deleteTransactionDialog (t) {
      if (t) {
        this.records.some(e => {
          if (+e.id === +t.id) {
            e.delete = true
            return true
          } else {
            return false
          }
        })
      }
      if (this.recordsForDeleting().length > 0) {
        this.showTransactionDelete = true
      }
    },

    cancelDelete () {
      this.records.forEach(e => {
        if (e.delete) {
          e.delete = false
        }
      })
      const checkbox = document.querySelector('#select-all')
      checkbox.checked = false
    },

    resetTransaction (t) {
      t.qbaccount_id = null
      t.qbentity_id = null
      t.qbclass_id = null
    },

    deleteTransaction (t) {
      if (this.loading) {
        return
      }

      if (this.recordsForDeleting().length > 0) {
        PlaidService.deleteTransactions(this.recordsForDeleting()).then(() => {
          this.$aimNotify.notify(null, 'info', 'Transactions Deleted', 'The transactions were deleted successfully.')
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        }).finally(() => {
          this.selectedTransaction = null
          this.loading = false
          this.refreshTable()
          this.$emit('refresh')
        })
      }
    },

    recordsForDeleting () {
      const items = [...this.records]
      return items.filter(e => e.delete)
    },

    save () {
      if (this.loading || !this.records || !this.records.length) {
        return false
      }
      this.loading = true
      PlaidService.saveCoding(this.records).then((response) => {
        this.$aimNotify.notify(null, 'info', 'Coding Saved', 'Coding information was saved successfully.')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        this.refreshTable()
        this.$emit('refresh')
      })
    },

    transactionOldEnoughToForce (date) {
      const tDate = new Date(date)
      const cDate = new Date()
      const utc1 = Date.UTC(tDate.getFullYear(), tDate.getMonth(), tDate.getDate())
      const utc2 = Date.UTC(cDate.getFullYear(), cDate.getMonth(), cDate.getDate())
      const diff = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24))
      return diff >= 5
    },

    settleTransaction (id) {
      if (this.loading || !this.records || !this.records.length) {
        return false
      }
      this.loading = true
      PlaidService.settleTransaction(id).then((response) => {
        this.$aimNotify.notify(null, 'info', 'Transaction Settled', 'Transaction was successfully forced into a settled state.')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        this.refreshTable()
        this.$emit('refresh')
      })
    }
  },

  watch: {
    currentPage: function (n, o) {
      if (n && +n !== +o) {
        this.refreshTable()
      }
    },

    perPage: function (n, o) {
      if (n && +n !== +o) {
        this.refreshTable()
      }
    }

  },

  computed: {
    startNumber: function () {
      return +this.perPage * (+this.currentPage - 1) + 1
    },
    endNumber: function () {
      return (+this.perPage * +this.currentPage > +this.totalRecords) ? +this.totalRecords : +this.perPage * +this.currentPage
    }
  }
}
</script>
