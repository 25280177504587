<template>
  <b-overlay :show="loading">
    <b-tabs
      align="center"
      active-nav-item-class="font-weight-bold"
      nav-class="coding-tab"
      active-tab-class=""
      content-class="text-dark"
      v-model="currenttab"
    >
      <b-tab
        title-item-class="coding-tab"
        title-link-class="coding-tab tab-item"
      >
        <template #title>Coding<span v-if="codingTotal > 0"> ({{ codingTotal }})</span></template>
        <b-card class="mt-0">
          <b-card-text>
            <Coding
              :companies="companies"
              :companyId="selectedCompanyId"
              :date="date"
              @selectCompany="setValAndRefresh('selectedCompanyId', $event)"
              @selectPeriod="setValAndRefresh('selectedPeriod', $event)"
              @total="setValAndRefresh('codingTotal', $event, 'number', false)"
              @refresh="triggerRefresh"
              @selectTab="setCurrentTab"
            ></Coding>
          </b-card-text>
        </b-card>
      </b-tab>

      <b-tab
        title-item-class="queue-tab"
        title-link-class="queue-tab tab-item"
      >
        <template #title>Queue<span v-if="queueTotal > 0"> ({{ queueTotal }})</span></template>
        <b-card class="mt-0">
          <b-card-text>
            <TransactionQueue
              :company="selectedCompany"
              :period="selectedPeriod"
              :refresh="refresh"
              @refresh="triggerRefresh"
              @total="setValAndRefresh('queueTotal', $event, 'number', false)"
              @switchtab="setCurrentTab($event)"
            ></TransactionQueue>
          </b-card-text>
        </b-card>
      </b-tab>

      <b-tab
        title-item-class="processing-tab"
        title-link-class="processing-tab tab-item"
      >
        <template #title>Processing<span v-if="processingTotal > 0"> ({{ processingTotal }})</span></template>
        <b-card class="mt-0">
          <b-card-text>
            <Processing
              :company="selectedCompany"
              :period="selectedPeriod"
              :refresh="refresh"
              @refresh="triggerRefresh"
              @total="setValAndRefresh('processingTotal', $event, 'number', false)"
            ></Processing>
          </b-card-text>
        </b-card>
      </b-tab>

      <b-tab
        title-item-class="feedback-tab"
        title-link-class="feedback-tab tab-item"
      >
        <template #title>Feedback<span v-if="feedbackTotal > 0"> ({{ feedbackTotal }})</span></template>
        <b-card>
          <b-card-text>
            <Feedback
              :company="selectedCompany"
              :period="selectedPeriod"
              :refresh="refresh"
              @refresh="triggerRefresh"
              @total="setValAndRefresh('feedbackTotal', $event, 'number', false)"
            ></Feedback>
          </b-card-text>
        </b-card>
      </b-tab>

      <b-tab
        title-item-class="completed-tab"
        title-link-class="completed-tab tab-item"
      >
        <template #title>Completed<span v-if="completedTotal > 0"> ({{ completedTotal }})</span></template>
        <b-card>
          <b-card-text>
            <Complete
              :company="selectedCompany"
              :refresh="refresh"
              :period="selectedPeriod"
              @refresh="triggerRefresh"
              @total="setValAndRefresh('completedTotal', $event, 'number', false)"
            ></Complete>
          </b-card-text>
        </b-card>
      </b-tab>

      <b-tab title-link-class="tab-item">
        <template #title><span :class="(+needsauthTotal > 0) ? 'text-danger font-weight-bold' : ''">Settings<span v-if="needsauthTotal > 0"> ({{ needsauthTotal }})</span></span></template>
        <b-card>
          <b-card-text>
            <PlaidMap
              v-if="+selectedCompanyId"
              :companyId="+selectedCompanyId"
              @refresh="triggerRefresh"
              @total="(val) => { needsauthTotal = +val }"
            ></PlaidMap>
          </b-card-text>
        </b-card>
      </b-tab>

    </b-tabs>
  </b-overlay>
</template>
<style scoped>
.coding-tab a {
  background: black !important;
  color: white !important;
}
</style>
<script>
import UserService from '@/services/UserService'
import AdminService from '@/services/AdminService'
import Coding from '@/components/bookkeeping/Coding'
import TransactionQueue from '@/components/bookkeeping/Queue'
import Processing from '@/components/bookkeeping/Processing'
import Feedback from '@/components/bookkeeping/Feedback'
import Complete from '@/components/bookkeeping/Complete'
import PlaidMap from '@/components/bookkeeping/PlaidMap'

export default {
  name: 'BookkeepingCoding',
  components: { Coding, TransactionQueue, Processing, Feedback, Complete, PlaidMap },
  data () {
    return {
      loading: false,
      companies: [],
      allCompanies: [],
      selectedCompanyId: null,
      selectedCompany: null,
      selectedPeriod: null,
      refresh: false,
      codingTotal: 0,
      queueTotal: 0,
      processingTotal: 0,
      feedbackTotal: 0,
      completedTotal: 0,
      needsauthTotal: 0,
      currenttab: 0,
      tabs: ['coding', 'queue', 'processing', 'feedback', 'complete', 'settings']
    }
  },

  props: {
    id: {
      type: [String, Number],
      default: 0
    },

    // year and month filter in YYYY-MM format
    date: {
      type: String,
      default: null
    },

    tab: {
      type: String,
      default: 'coding'
    }
  },

  created () {
    if (this.tab) {
      this.setCurrentTab(this.tab)
    }

    if (this.id && +this.id !== 0) {
      this.selectedCompanyId = +this.id
    }
  },

  mounted () {
    this.loading = true

    AdminService.getCompanies().then(
      (companies) => {
        companies.forEach(c => {
          this.allCompanies.push({ value: +c.id, text: c.name })
        })

        if (UserService.isAdmin()) {
          this.init(companies)
        } else {
          UserService.getCompanies().then(
            (companies) => {
              this.init(companies)
            }
          )
        }
      }
    )
  },

  methods: {
    init (companies) {
      if (companies && companies.length) {
        // if (companies.length === 1) {
        //   this.selectedCompanyId = +companies[0].id
        // }
        companies.forEach((c) => {
          this.companies.push({ value: +c.id, text: c.name })
        })
        this.companies = this.companies.sort((a, b) => ('' + a.text).localeCompare(b.text))
      }
      this.setSelectedCompany(+this.selectedCompanyId)
      this.loading = false
    },

    setValAndRefresh (item, val, type = 'string', refresh = true) {
      if (type === 'number') {
        this[item] = +val
      } else {
        this[item] = val
      }
      if (refresh) {
        this.triggerRefresh()
      }
    },

    setCurrentTab (val) {
      this.currenttab = +this.tabs.indexOf(val)
      if (+this.currenttab < 0) {
        this.currenttab = 0
      }
    },

    triggerRefresh () {
      this.refresh = true
      this.$nextTick(() => {
        this.refresh = false
      })
    },

    setSelectedCompany (n) {
      if (n) {
        this.selectedCompany = null
        if (this.allCompanies && this.allCompanies.length) {
          this.allCompanies.some((c) => {
            if (+c.value === +n) {
              this.selectedCompany = c
              return true
            } else return false
          })
        }
      }
    }
  },

  watch: {
    selectedCompanyId: function (n, o) {
      if (+n) {
        this.setSelectedCompany(n)
      }
    }
  }
}
</script>
