<template>
  <b-overlay
    :show="loading"
    v-if="company"
  >
    <b-row>
      <b-col class="text-left">
        <h2>{{ company.text }}</h2>
      </b-col>
      <b-col class="text-right small">
        <QueueStatus
          :status="status"
          @refresh="$root.$emit('bv::refresh::table', 'feedbackTable')"
        ></QueueStatus>
      </b-col>
    </b-row>

    <b-table
      id="feedbackTable"
      :fields="headers"
      :items="getRecords"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.reverse"
      bordered
      sticky-header="600px"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      striped
      details-td-class="w-100 p-0"
      @row-clicked="(item) => toggleDetailRow(item)"
    >
      <template #cell(qbtype)="data">
        {{ data.item.qbtype.replace(/([A-Z])/g, ' $1') }}
      </template>

      <template #cell(credit)="data">
        <strong>{{ (data.item.debit !== 0) ? data.item.debit : data.item.credit | currency }}</strong>
      </template>

      <template #cell(started)="data">
        <span v-if="data.item.messagesunread > 0"><strong>Unread ({{ data.item.messagesunread }})</strong></span>
        <span v-else-if="!data.item.messages.length">Needs Info</span>
        <span v-else-if="data.item.messagesneedresponse">Needs Response</span>
        <span v-else>Responded</span>
      </template>

      <template #row-details="row">
        <Messaging
          :transaction="row.item"
          @toggle="row.toggleDetails"
          @update="updateMessage"
          @refresh="$emit('refresh')"
        ></Messaging>
      </template>
    </b-table>

    <b-row class="h-100">
      <b-col class="text-left">{{ records.length }} transactions processing</b-col>
      <!-- <b-col class="text-center">
        <b-button
          variant="danger"
          @click.prevent="resync"
          :disabled="synchronizing"
        >Re-Sync with QB</b-button>
      </b-col> -->
      <b-col class="text-right">
        Total: <strong>{{ total | currency }}</strong>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import PlaidService from '@/services/PlaidService'
import FeedbackService from '@/services/FeedbackService'
import QueueStatus from '@/components/bookkeeping/widgets/QueueStatus'
import Messaging from '@/components/feedback/Messaging'

export default {
  name: 'Feedback',
  components: { QueueStatus, Messaging },
  props: {
    company: {
      type: Object,
      default () { return null }
    },
    period: {
      type: String,
      default () { return null }
    },
    refresh: Boolean
  },

  data () {
    return {
      loading: false,
      updated: false,
      synchronizing: false,
      processing: false,
      showProcessQueueConfirmation: false,
      records: [],
      total: 0,
      status: null,
      filter: null,
      headers: [
        {
          key: 'businessdate',
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'linkedqbaccount',
          label: 'Source',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbtype',
          label: 'Type',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbentity',
          label: 'Vendor',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbaccount',
          label: 'Account',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbclass',
          label: 'Class',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'credit',
          label: 'Amount',
          sortable: true,
          tdClass: 'align-middle text-right',
          thClass: 'text-right'
        },
        {
          key: 'started',
          label: 'Status',
          sortable: true,
          tdClass: 'align-middle'
        }
      ],
      sort: {
        column: 'businessdate',
        reverse: false
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (!this.company || !this.company.value) {
        return false
      }
      if (this.loading) {
        return false
      }
      this.$root.$emit('bv::refresh::table', 'feedbackTable')
    },

    getRecords (ctx) {
      if (!this.company || !+this.company.value) {
        return null
      }
      const data = {
        company_id: +this.company.value,
        feedback: true,
        perPage: 0,
        page: 1,
        sort: {
          column: ctx.sortBy,
          reverse: ctx.sortDesc
        }
      }
      return FeedbackService.search(data).then((response) => {
        const curlen = (this.records) ? this.records.length : 0
        this.records = response.data.info.transactions
        this.total = response.data.info.amount
        this.status = response.data.info.status
        this.$emit('total', +this.records.length)
        if (curlen !== this.records.length) {
          this.$emit('refresh')
        }
        return this.records || []
      })
    },

    resync () {
      if (!this.company || !+this.company.value || this.synchronizing) {
        return false
      }
      const data = {
        company_id: +this.company.value,
        period: this.period
      }
      PlaidService.synchronize(data).then((response) => {
        this.synchronizing = response.data.info.synchronizing
        this.processing = response.data.info.processing
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    },

    updateMessage (msg) {
      let transaction = null
      this.records.some((t) => {
        if (+t.id === +msg.plaidtransaction_id) {
          transaction = t
          return true
        } else return false
      })
      if (transaction) {
        let updated = false
        transaction.messages.some((m, idx) => {
          if (+m.id === +msg.id) {
            transaction.messages[idx] = msg
            transaction.messagesunread--
            updated = true
            return true
          } else return false
        })
        if (updated) {
          this.updated = updated
        }
      }
    },

    toggleDetailRow (item) {
      this.$set(item, '_showDetails', !item._showDetails)
      if (this.updated) {
        this.updated = false
        this.$emit('refresh')
      }
    }
  },

  watch: {
    refresh: function (n, o) {
      if (n && n !== o) {
        this.$root.$emit('bv::refresh::table', 'feedbackTable')
      }
    }
  }
}
</script>
