<template>
  <div>
    <b-button
      variant="danger"
      @click.prevent="show = !show"
    >
      Transaction Import
    </b-button>

    <b-modal
      v-model="show"
      id="plaidimport"
      title="Import Plaid Transactions"
      @hidden="reset"
      @shown="init"
      @ok="queue"
      ok-variant="primary"
      :busy="loading"
      :ok-disabled="isInvalid"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay :show="loading">
        <b-row>
          <b-col>
            <p>This will import all available transactions from Plaid starting at the date given below. You may select one or more accounts to import. Once submitted, transactions will begin appearing in the coding queue usually within 5-10 minutes.</p>
          </b-col>
        </b-row>

        <b-form-group
          label="Start Date:"
          label-cols="4"
          label-align="right"
          content-cols="5"
          class="m-3"
        >
          <b-form-datepicker
            v-model="startdate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            required
            :state="startdate !== null"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group
          label="End Date:"
          label-cols="4"
          label-align="right"
          content-cols="5"
          class="m-3"
        >
          <b-form-datepicker
            v-model="enddate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          ></b-form-datepicker>
        </b-form-group>

        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th class="text-center"></b-th>
              <b-th>Institution</b-th>
              <b-th>Type</b-th>
              <b-th>Account</b-th>
              <b-th>Last Four</b-th>
              <b-th class="text-center">Status</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="a in accounts"
              :key="a.id"
            >
              <b-td class="align-middle text-center">
                <b-checkbox
                  v-model="a.selected"
                  value="1"
                  unchecked-value="0"
                  :disabled="a.needsauth !== null || (+a.hashistorical === 0 && +a.daysold < 1)"
                ></b-checkbox>
              </b-td>
              <b-td class="align-middle">{{ a.institution }}</b-td>
              <b-td class="align-middle">{{ a.subtype }}</b-td>
              <b-td class="align-middle">{{ a.qbaccount }}</b-td>
              <b-td class="align-middle">{{ a.mask }}</b-td>
              <b-td class="align-middle text-center">{{ (a.needsauth !== null) ? 'Needs Auth' : (+a.hashistorical > 0 || +a.daysold > 0) ? 'Ready' : 'Pending' }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import PlaidService from '@/services/PlaidService'

export default {
  name: 'PlaidImport',

  props: {
    companyId: Number
  },

  data () {
    return {
      loading: false,
      show: false,
      accounts: [],
      startdate: null,
      enddate: null
    }
  },

  methods: {
    init () {
      this.loading = true
      PlaidService.getCompany(+this.companyId).then(
        (response) => {
          this.accounts = []
          if (response.data.info.plaid && response.data.info.plaid.length) {
            response.data.info.plaid.forEach((pi) => {
              if (+pi.id > 0 && pi.accounts && pi.accounts.length > 0) {
                pi.accounts.forEach((pa) => {
                  if (+pa.id > 0 && +pa.ignore === 0) {
                    pa.selected = (pa.needsauth === null && (+pa.hashistorical > 0 || +pa.daysold > 0)) ? 1 : 0
                    this.accounts.push(pa)
                  }
                })
              }
            })
          }
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    reset () {
      this.accounts = []
      this.startdate = null
      this.enddate = null
      this.show = false
    },

    queue () {
      // disable plaid for now
      alert('Plaid Diabled at this time')
      return true
      // eslint-disable-next-line no-unreachable
      if (this.loading || this.isInvalid) {
        return false
      }
      this.loading = true
      const formdata = {
        startdate: this.startdate,
        enddate: this.enddate,
        accounts: []
      }
      this.accounts.forEach((a) => {
        if (+a.selected > 0) {
          formdata.accounts.push({ plaiditem_id: +a.plaiditem_id, plaidaccount_id: +a.id })
        }
      })

      // eslint-disable-next-line no-unreachable
      PlaidService.queueImport(formdata).then((response) => {
        this.$aimNotify.notify(response, 'info', 'Import Queued', 'Historical transaction imports were successfully queued.')
        this.reset()
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  },

  computed: {
    isInvalid: function () {
      if (this.loading || this.startdate === null || this.accounts.length === 0) {
        return true
      } else {
        return !this.hasSelected
      }
    },

    hasSelected: function () {
      let selected = 0
      if (this.accounts && this.accounts.length > 0) {
        this.accounts.some((a) => {
          if (a.selected > 0) {
            selected = 1
            return true
          } else return false
        })
      }
      return (selected > 0)
    }
  }
}
</script>
