<template>
  <b-form-select
    v-model="selection"
    :options="options"
    @change="$emit('selected', selection)"
    size="sm"
    title="Quickbooks Account"
    :class="(selection) ? ((coded) ? 'is-valid' : 'is-warning') : 'is-invalid'"
    class="small-select"
  >
    <template #first>
      <b-form-select-option :value="null">Select Account</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
export default {
  name: 'SelectAccount',

  props: {
    options: Array,
    coded: Number,
    value: {
      type: [Number, String],
      default: null
    }
  },

  data () {
    return {
      selection: null,
      loaded: false
    }
  },

  mounted () {
    if (this.value) {
      this.selection = this.value
    } else if (this.options) {
      this.selection = this.options[0].value
    }
    this.$nextTick(() => {
      this.$emit('selected', this.selection)
      this.loaded = true
    })
  },

  watch: {
    value: function (n, o) {
      if (this.loaded && n !== o) {
        this.selection = n
        // this.$emit('selected', this.selection)
      }
    }
  }
}
</script>
