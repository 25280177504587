<template>
  <b-overlay :show="loading">
    <b-card
      v-for="i in company.plaid"
      :key="i.id"
      class="m-2"
    >
      <b-card-title>
        <b-row>
          <b-col>{{ i.institution }}</b-col>
          <b-col>{{ (i.accounts && i.accounts.length) ? i.accounts.length + ' Accounts' : 'Pending' }}</b-col>
          <b-col>
            <b-btn
              variant="danger"
              size="sm"
              @click="remove(i)"
            >Remove</b-btn>
          </b-col>
        </b-row>
      </b-card-title>

      <b-table-simple v-if="i.accounts.length > 0">
        <b-thead>
          <b-tr>
            <b-th>Institution</b-th>
            <b-th>Type</b-th>
            <b-th>Account Name</b-th>
            <b-th>Last Four</b-th>
            <b-th>QuickBooks Account</b-th>
            <b-th class="text-center">Ignore</b-th>
            <b-th class="text-center">Auto-Settle</b-th>
            <b-th class="text-center"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="a in i.accounts"
            :key="a.id"
          >
            <b-td class="align-middle">{{ a.institution }}</b-td>
            <b-td class="align-middle">{{ a.type }} / {{ a.subtype }}</b-td>
            <b-td class="align-middle">{{ a.name }}<br><span class="small"><strong>Plaid Item ID: </strong>{{ a.plaiditem_externalid }}</span>
              <br><span class="small"><strong>Link Session ID: </strong>{{ a.session_id.replaceAll('"', '') }}</span></b-td>
            <b-td class="align-middle">{{ a.mask }}</b-td>
            <b-td class="align-middle">
              <b-form-select
                v-model="a.qbaccount_id"
                :options="accounts"
                :disabled="+a.ignore > 0"
                :class="(!+a.qbaccount_id && !+a.ignore) ? 'is-invalid' : ((+a.qbaccount_id) ? 'is-valid' : '')"
              >
                <template #first>
                  <b-form-select-option :value="null">Select Account</b-form-select-option>
                </template>
              </b-form-select>
            </b-td>
            <b-td class="align-middle text-center">
              <b-checkbox
                v-model="a.ignore"
                value="1"
                unchecked-value="0"
                :disabled="+a.qbaccount_id > 0"
                :class="(!+a.qbaccount_id && !+a.ignore) ? 'is-invalid' : 'is-valid'"
              ></b-checkbox>
            </b-td>
            <b-td class="align-middle text-center">
              <b-checkbox
                v-model="a.autosettle"
                value="1"
                unchecked-value="0"
              ></b-checkbox>
            </b-td>
            <b-td class="align-middle text-center">
              <PlaidLink
                :company-id="+companyId"
                :item-id="+a.plaiditem_id"
                :variant="a.needsauth ? 'danger' : 'secondary'"
                button-title="Re-Auth"
                title="Re-authorize Plaid Bank Account"
              ></PlaidLink>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-row
      align-h="around"
      class="text-center m-3"
    >
      <b-col class="text-left">
        <PlaidLink
          :company-id="+companyId"
          variant="success"
          button-title="Connect New Plaid Account"
          title="Add New Plaid Bank Account"
        ></PlaidLink>
      </b-col>

      <b-col v-if="accounts.length > 0">
        <PlaidImport :company-id="+companyId"></PlaidImport>
      </b-col>

      <b-col>
        <b-button
          :to="'/bookkeeping/code/' + +companyId"
          variant="info"
        >Transaction Coding Queue</b-button>
      </b-col>

      <b-col class="text-right">
        <b-button
          variant="primary"
          @click.prevent="save"
          :disabled="loading"
        >Save Plaid Account Settings</b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import PlaidService from '@/services/PlaidService'
import PlaidLink from '@/components/plaid/Link'
import PlaidImport from '@/components/bookkeeping/PlaidImport'

export default {
  name: 'PlaidMap',
  components: { PlaidLink, PlaidImport },

  props: {
    companyId: Number
  },

  data () {
    return {
      loading: false,
      company: {},
      items: [],
      accounts: []
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (!this.companyId) {
        return false
      }
      if (this.loading) {
        return false
      }
      this.loading = true
      PlaidService.getCompany(+this.companyId).then(
        (response) => {
          this.company = response.data.info
          this.company.accounts.banks.forEach((a) => {
            this.accounts.push({ text: a.fullname, value: +a.id, type: a.accounttype })
          })
          let unauthed = 0
          this.company.plaid.forEach((i) => {
            if (i.needsauth) {
              unauthed++
            }
          })
          this.$emit('total', +unauthed)
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    save () {
      if (this.loading) {
        return false
      }
      this.loading = true
      PlaidService.saveAccountMap(this.company.plaid).then(() => {
        this.$aimNotify.notify(null, 'info', 'Accounts Updated', 'Plaid account mapping information was saved successfully.')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        this.init()
        this.$emit('refresh')
      })
    },

    remove (item) {
      if (this.loading || !item || !item.id) {
        return false
      }
      this.$bvModal.msgBoxConfirm('Are you sure you wish to remove this ' + item.institution + ' plaid item? All accounts and transactions from this plaid connection will be removed.').then(
        val => {
          if (val) {
            this.$bvModal.msgBoxConfirm('Think about this. All transactions ever imported for this ' + item.institution + ' plaid connection will be removed! Are you sure?').then(
              val => {
                if (val) {
                  this.loading = true
                  PlaidService.removePlaidItem(+item.id).then(
                    (response) => {
                      this.$emit('refresh')
                      this.$aimNotify.notify(response)
                    },
                    (err) => {
                      this.$aimNotify.error(err.response)
                    }
                  ).finally(() => {
                    this.loading = false
                  })
                }
              }
            )
          }
        }
      )
    }
  }
}
</script>
