<template>
  <b-modal
    v-model="showAddVendor"
    size="sm"
    title="Add New Vendor"
    no-close-on-backdrop
    no-close-on-esc
    @cancel="$emit('cancel')"
    @ok="$emit('newvendor', name)"
    @shown="focusInput()"
  >
    <form @submit.prevent="$emit('newvendor', name)">
      <p class="text-center">Enter the new vendor name:</p>
      <b-form-input v-model.trim="name" :id="'newVendorName-' + randomId"></b-form-input>
    </form>
  </b-modal>
</template>
<script>
export default {
  name: 'AddVendor',

  props: {
    show: Boolean
  },

  data () {
    return {
      randomId: 1,
      showAddVendor: false,
      name: null
    }
  },

  methods: {
    focusInput () {
      // throwing in a random id to prevent any issues with ID
      document.getElementById('newVendorName-' + this.randomId).focus()
    }
  },

  watch: {
    show: function (n) {
      this.showAddVendor = n
      if (n) {
        this.randomId = Math.floor(Math.random() * 10000)
      }
    }
  }
}
</script>
