<style scoped>
.small-select {
  max-width: 225px;
  font-size: 90%;
}
</style>
<template>
  <b-row
    class="text-center mb-1"
    no-gutters
    align-v="center"
  >
    <b-col cols="12">
      <span
        class="font-weight-bold mb-0 pointer"
        @click="edit = !edit"
      >Vendor: {{ (name) ? name : 'Unknown' }} <b-icon
          :variant="(!name) ? 'warning' : 'success'"
          :icon="(name) ? 'check-circle-fill' : 'exclamation-octagon-fill'"
        ></b-icon></span>
    </b-col>
    <b-col
      cols="12"
      v-if="edit"
    >
      <b-input-group>
        <template #append>
          <b-button
            variant="outline-info"
            size="sm"
            title="Add New Vendor"
            @click="showAdd = true"
            class="small-select"
          >
            <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
          </b-button>
        </template>
        <b-form-select
          v-model.lazy="selection"
          :options="options"
          @change="$emit('selected', $event)"
          size="sm"
          title="Quickbooks entity"
          :class="(selection) ? ((coded) ? 'is-valid' : 'is-warning') : 'is-warning'"
          class="small-select"
        >
          <template #first>
            <b-form-select-option :value="null">Select Vendor</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group>

      <AddVendor
        :show="showAdd"
        @cancel="showAdd = false"
        @newvendor="(v) => { setNewVendorName(v) }"
      ></AddVendor>
    </b-col>
  </b-row>
</template>
<script>
import AddVendor from '@/components/bookkeeping/widgets/AddVendor'

export default {
  name: 'SelectVendor',
  components: { AddVendor },

  props: {
    options: Array,
    coded: Number,
    value: {
      type: [Number, String],
      default: null
    },
    vendorname: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      selection: null,
      name: null,
      edit: false,
      showAdd: false
    }
  },

  mounted () {
    if (this.value) {
      this.selection = this.value
      this.options.some((o) => {
        if (+o.value === this.selection) {
          this.name = o.text
          return true
        } else return false
      })
      this.$emit('selected', this.selection)
      this.edit = false
    } else {
      this.name = null
    }
  },

  methods: {
    setNewVendorName (n) {
      this.selection = null
      // make sure we don't already have this vendor
      this.options.some((o) => {
        if (o.text === n) {
          this.selection = o.value
          this.name = o.text
          return true
        } else return false
      })

      // fire this to either reset the selection or set it since we found it above
      this.$emit('selected', this.selection)

      if (!this.selection) {
        this.name = n
        this.$emit('newvendor', this.name)
      }

      this.showAdd = false
      this.edit = false
    }
  },

  watch: {
    value: function (n, o) {
      if (n !== o) {
        this.selection = n
        if (this.selection) {
          this.options.some((o) => {
            if (+o.value === this.selection) {
              this.name = o.text
              return true
            } else return false
          })
        } else {
          this.name = null
        }
        this.$emit('selected', this.selection)
        if (this.selection) {
          this.edit = false
        }
      }
    },

    vendorname: function (n) {
      this.name = n
      this.$emit('newvendor', this.name)
    }
  }
}
</script>
