<template>
  <div v-if="status && status !== null">
    <strong>Status: <span :class="(status.status === 'OK') ? 'text-success' : 'text-danger'">
        <b-icon :icon="(status.status === 'OK') ? 'check-circle-fill' : 'x-circle-fill'"></b-icon> {{ status.status }}
      </span>
    </strong>
    <br>
    {{ (status.ts !== null) ? status.ts.substring(5, 16) : '' }}
    <b-icon-arrow-repeat
      class="text-primary pointer"
      @click.prevent="$emit('refresh')"
    ></b-icon-arrow-repeat>
  </div>
</template>
<script>
export default {
  name: 'QueueStatus',
  props: {
    status: {
      type: Object,
      default () { return null }
    }
  }
}
</script>
